<template>
    <v-card outlined dense>
        <v-card-text>
            <v-stepper v-model="stepVenta">
                <v-stepper-header>
                    <v-stepper-step :complete="stepVenta > 1" step="1"> PROD. PREPARANDO </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="stepVenta > 2" step="2">PROD. TERMINADO</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="stepVenta > 3" step="3">PROD. EN CAMINO </v-stepper-step>
                    <v-divider></v-divider>

                    <v-stepper-step step="4">PROD. ENTREGADO</v-stepper-step>
                </v-stepper-header>
                <h3 class="blue--text pb-3" v-if="datos.isEmailEntregado && datos.isEmailEnCamino && datos.isEmailPreparando && datos.isEmailTerminado">
                    Se cumplio con la cadena de correo
                </h3>
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card class="mb-12" color="grey lighten-2">
                            <v-card-text>
                                <h3 class="blue--text pb-3" v-if="datos.isEmailPreparando">Ya se envió producto preparando</h3>
                                <v-text-field outlined v-model="datos.emailClient" label="Email Cliente " placeholder="email" />
                            </v-card-text>
                            <v-card-actions class="grey lighten-3 pa-3">
                                <v-spacer> </v-spacer>

                                <v-btn @click="enviarProductoPreparando" right :loading="loadingPedido" dark color="green">
                                    <v-icon class="pr-2">mdi-chevron-right-circle </v-icon>
                                    ENVIAR CONFIRMACIÓN PROD. PREPARANDO
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card class="mb-12" color="grey lighten-2">
                            <v-card-text>
                                <h3 class="blue--text pb-3" v-if="datos.isEmailTerminado">Ya se envió producto terminado</h3>
                                <v-text-field outlined v-model="datos.emailClient" label="Email Cliente" placeholder="email" />
                                <v-text-field
                                    outlined
                                    v-if="saldoPendiente > 0"
                                    v-model="saldoPendiente"
                                    label="Saldo Pendiente"
                                    placeholder="Saldo Pendiente"
                                />
                                <v-text-field outlined v-if="saldoPendiente <= 0" label="Pago Completo" placeholder="Pago Completo" />
                            </v-card-text>
                            <v-card-actions class="grey lighten-3 pa-3">
                                <v-spacer></v-spacer>

                                <v-btn @click="enviarProductoTerminado" right :loading="loadingPedido" dark color="green">
                                    <v-icon class="pr-2">mdi-chevron-right-circle </v-icon>
                                    ENVIAR CONFIRMACION DE PROD. TERMINADO
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card class="mb-12" color="grey lighten-2">
                            <v-card-text>
                                <h3 class="blue--text pb-3" v-if="datos.isEmailEnCamino">Ya se envió producto en camino</h3>
                                <v-text-field outlined v-model="datos.emailClient" label="Email Cliente3" placeholder="email" />
                            </v-card-text>
                            <v-card-actions class="grey lighten-3 pa-3">
                                <v-spacer></v-spacer>

                                <v-btn @click="enviarProductoEnCamino" right :loading="loadingPedido" dark color="green">
                                    <v-icon class="pr-2">mdi-chevron-right-circle </v-icon>
                                    ENVIAR CONFIRMACIÓN EN CAMINO
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="4">
                        <!-- <v-card class="mb-2" color="grey lighten-2">
                            <v-card-text>
                                <h3 class="blue--text pb-3">TODAVÍA NO IMPLEMENTADO</h3>
                            </v-card-text>
                        </v-card> -->
                        <v-card class="mb-12" color="grey lighten-2">
                            <v-card-text>
                                <h3 class="blue--text pb-3" v-if="datos.isEmailEntregado">Ya se envió producto entregado</h3>
                                <v-text-field outlined v-model="datos.emailClient" label="Email Cliente" placeholder="email" />
                                <v-text-field outlined v-model="dataLinkProd" label="Link del Producto" placeholder="link" />
                                <v-autocomplete
                                    outlined
                                    v-model="dataNameLinkProd"
                                    item-text="name"
                                    :items="dataProductos"
                                    label="Escoge el producto representativo"
                                    placeholder="producto"
                                />
                            </v-card-text>
                            <v-card-actions class="grey lighten-3 pa-3">
                                <v-spacer></v-spacer>
                                <v-btn @click="enviarProductoEntregado" right :loading="loadingPedido" dark color="green">
                                    <v-icon class="pr-2">mdi-chevron-right-circle </v-icon>
                                    ENVIAR CONFIRMACIÓN ENTREGADO
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <!-- <v-btn @click="retornar2" right :loading="loadingPedido" dark color="green">
                            <v-icon class="pr-2">mdi-chevron-right-circle </v-icon>
                            REGRESAR
                        </v-btn> -->
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

            <v-row justify="center">
                <v-col class="shrink">
                    <v-btn v-if="stepVenta != 1" @click="retornar" right :loading="loadingPedido" dark color="red">
                        <v-icon class="ma-2">mdi-chevron-left-circle </v-icon>
                        ATRAS
                    </v-btn>
                </v-col>

                <div class="mx-4 hidden-sm-and-down"></div>
                <v-spacer> </v-spacer>
                <v-col class="shrink">
                    <v-btn v-if="stepVenta != 4" @click="omitir" right dark color="red">
                        ADELANTE
                        <v-icon class="ma-2">mdi-chevron-right-circle </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <!--             <v-row dense align="center" justify="center" class="pa-2">
                <v-col cols="12">
                    <h3 class="green--text" v-if="datos.isEmailPreparando">Ya se envió la confirmación de pedido</h3>
                </v-col>

                <v-col cols="12" sm="12">
                    <v-text-field outlined dense v-model="this.datos.emailClient" label="Email Cliente" placeholder="direccion" />
                </v-col>
                <v-col cols="12" sm="12">
                    <v-text-field v-model="saldoPendiente" :prefix="this.datos.idTipoMoneda == '1' ? 'S/' : '$'"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-btn @click="enviarProductoPreparando" :loading="loadingPedido" dark color="green">ENVIAR CORREO </v-btn>
                </v-col>
            </v-row> -->
        </v-card-text>
    </v-card>
</template>
<script>
import { sleep } from '@/utils/genericUtils'
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { format } from 'date-fns'

export default {
    filters: {},
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    components: {},
    data() {
        return {
            // stepVenta: 1,
            loadingNext: false,
            loadingPedido: false,
            importe: 0,
            position: 1,
            dataNameLinkProd: null,
            messageBotom: null,
            message: null,
            dataProductos: 1,
            dataLinkProd: '',
            valid: true,
            mountStep: false,
            saldoPendiente: 0
        }
    },
    computed: {
        ...mapState('ventas', ['datos']),
        ...mapState('inventario', ['listaEstados']),
        ...mapState('pagos', ['listaPagos']),
        stepVenta() {
            if (this.mountStep) {
                return this.position
            } else if (this.datos.isEmailEntregado) {
                return 4
            } else if (this.datos.isEmailEnCamino) {
                return 3
            } else if (this.datos.isEmailTerminado) {
                return 2
            } else {
                return 1
            }
        }
    },
    created() {
        const atributos = this.datos.atributos
        console.log('atributosv2', atributos, typeof atributos)
        const dataLinkProd = atributos?.dataLinkProd || null
        const dataNameLinkProd = atributos?.dataNameLinkProd || null

        const dataProd = this.datos.productos.map((item) => {
            const data = item.attributes ? JSON.parse(item.attributes) : null
            const linkProd = data?.find((x) => x.value)?.value ?? ''
            return {
                name: item.description ?? '',
                link: linkProd
            }
        })
        this.dataProductos = dataProd
        console.log('this.dataProductos---', this.dataProductos)
        if (dataLinkProd || dataNameLinkProd) {
            this.dataLinkProd = dataLinkProd
            this.dataNameLinkProd = dataNameLinkProd
            return
        }
        if (this.dataProductos.length > 0) {
            this.dataNameLinkProd = this.dataProductos[0].name

            const dataProductos = this.dataProductos.find((option) => option.name === this.dataNameLinkProd)
            this.dataLinkProd = dataProductos ? dataProductos.link : ''
        }
    },
    methods: {
        async retornar() {
            this.mountStep = true
            switch (this.stepVenta) {
                case 2:
                    this.datos.isEmailTerminado = 0
                    this.datos.isEmailPreparando = 0
                    this.position = 1
                    break

                case 3:
                    this.datos.isEmailEnCamino = 0
                    this.position = 2
                    break

                case 4:
                    this.datos.isEmailEntregado = 0
                    this.position = 3
                    break
            }
        },
        async omitir() {
            //Funcion para continuar con la ruta de correos
            this.mountStep = true
            if (this.stepVenta == 1 && !this.datos.isEmailTerminado && !this.datos.isEmailEntregado && !this.datos.isEmailEnCamino) {
                return (this.position = 2)
            } else if ((this.stepVenta == 2 || this.stepVenta == 1) && !this.datos.isEmailEntregado && !this.datos.isEmailEnCamino) {
                return (this.position = 3)
            } else if ((this.stepVenta == 3 || this.stepVenta == 1) && !this.datos.isEmailEntregado) {
                return (this.position = 4)
            }
        },

        async productoPrice() {
            const { productos } = this.datos
            //console.log('productos-.-.-.-.', productos)
            const prodPrice = productos.find((producto) => producto.price > productos[0].price) || productos[0]
            if (prodPrice) {
                if (prodPrice.attributes) {
                    const changeObject = JSON.parse(prodPrice.attributes)
                    //console.log('changeObject', changeObject)
                    const ecommerceLink = changeObject.find((item) => item.description == 'ECOMMERCE LINK')?.value
                    this.datos.attributeLink = ecommerceLink ? ecommerceLink : ''
                }
                this.datos.productHigherPrice = prodPrice.description
                return prodPrice
            } else {
                this.$store.commit('mainUI/OPEN_MODAL', {
                    state: true,
                    text: 'No se ha registrado ningún producto',
                    color: 'green'
                })
                await sleep(2500)
                this.$store.commit('mainUI/OPEN_MODAL', { state: false })
            }
        },

        async enviarProductoPreparando() {
            this.datos.isEmailPreparando = 1
            //console.log('this.datos ', this.datos)
            this.loadingPedido = true
            try {
                const data = await this.$store.dispatch('reporte/sendEmailProductoPreparando', this.datos)
                //console.log('data ', data)
                if (data.status == 200) {
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Se ha enviado el correo satisfactoriamente',
                        color: 'green'
                    })
                }
            } catch (error) {
                console.log('error ', error)
            }
            await this.$store.dispatch('ventas/getReservas', {})

            this.loadingPedido = false
        },
        async enviarProductoTerminado() {
            //console.log('this.datos ', this.datos)
            this.datos.isEmailTerminado = 1
            this.loadingPedido = true
            try {
                const body = {
                    idCorreoCoorporativo: 2,
                    idVenta: this.datos.id,
                    correoDestino: this.datos.emailClient,
                    saldoPendiente: this.saldoPendiente
                }
                const data = await this.$store.dispatch('reporte/sendEmailProductoTerminado', body)
                //console.log('data ', data)

                if (data.status == 200) {
                    //console.log('here-1')
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Se ha enviado el correo satisfactoriamente',
                        color: 'green'
                    })
                }
            } catch (error) {
                console.log('error ', error)
            }
            await this.$store.dispatch('ventas/getReservas', {})

            this.loadingPedido = false
        },

        async enviarProductoEnCamino() {
            this.datos.isEmailEnCamino = 1
            //console.log('this.datos ', this.datos)
            this.loadingPedido = true
            try {
                const body = {
                    idCorreoCoorporativo: 2,
                    idVenta: this.datos.id,
                    full_name: this.datos.full_name,
                    correoDestino: this.datos.emailClient
                }
                //console.log('body --> ', body)
                const data = await this.$store.dispatch('reporte/sendEmailProductoEnCamino', body)
                //console.log('data ', data)
                if (data.status == 200) {
                    //console.log('here-1')
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text: 'Se ha enviado el correo satisfactoriamente',
                        color: 'green'
                    })
                }
            } catch (error) {
                console.log('error ', error)
            }
            await this.$store.dispatch('ventas/getReservas', {})

            this.loadingPedido = false
        },
        async enviarProductoEntregado() {
            if (this.dataLinkProd && this.dataLinkProd != '') {
                this.datos.isEmailEntregado = 1
                //console.log('this.datos ', this.datos)
                this.loadingPedido = true
                try {
                    const atributos = {
                        ...this.datos.atributos,
                        dataLinkProd: this.dataLinkProd,
                        dataNameLinkProd: this.dataNameLinkProd
                    }
                    const body = {
                        idCorreoCoorporativo: 2,
                        idVenta: this.datos.id,
                        link: this.dataLinkProd,
                        full_name: this.datos.full_name,
                        correoDestino: this.datos.emailClient,
                        ...this.datos,
                        atributos
                    }
                    this.$store.commit('ventas/UPDATE_DATOS', {
                        atributos
                    })
                    console.log('body --> ', body)
                    const data = await this.$store.dispatch('reporte/sendEmailProductoEntregado', body)
                    //console.log('data ', data)
                    if (data.status == 200) {
                        //console.log('here-1')
                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text: 'Se ha enviado el correo satisfactoriamente',
                            color: 'green'
                        })
                    }
                } catch (error) {
                    //console.log('error ', error)
                }
                await this.$store.dispatch('ventas/getReservas', {})

                this.loadingPedido = false
            } else {
                this.$store.commit('mainUI/OPEN_BAR', {
                    state: true,
                    text: 'Falta algun campo por llenar',
                    color: 'green'
                })
            }
        }
    },
    async mounted() {
        await this.$store.dispatch('inventario/estados')
        await this.$store.dispatch('pagos/listaPagosById', this.datos)
        //console.log('this.datos', this.datos)
        this.saldoPendiente = 0
        let importe = 0
        for (const iterator of this.listaPagos) {
            //console.log('listapago', iterator)
            importe = importe + iterator.importe
        }
        this.productoPrice()
        this.saldoPendiente = (this.datos.importeTotal - importe).toFixed(2)
    }
}
</script>
